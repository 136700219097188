/* generic-backgrounds.scss */

.grey-bg {
    background: $grey-alt;
}

.white-bg {
    background: $white;
}

.green-bg {
    background: $green;
    color: $white;
}
