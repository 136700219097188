/* generic-mobile.scss */

@media (max-width: 700px) {
    html,
    body {
        font-size: 95%;
    }

    .width-10,
    .width-20,
    .width-30,
    .width-40,
    .half,
    .width-60,
    .width-70,
    .width-80,
    .width-90,
    .width-100,
    .third,
    .two-third,
    .third-with-margin,
    .half-with-margin {
        margin: 0;
        width: 100%;

        &:first-child {
            width: 100%;
        }
    }

    .quarter {
        width: 100%;
    }

    .three-quarter {
        width: 100%;
    }

    .quarter-with-margin {
        margin: 0 1%;
        width: 98%;
    }

    .section {
        padding: 20px 0;
    }

    .small-contain,
    .medium-contain,
    .large-contain {
        width: 100%;
    }

    .contain {
        padding: 20px;
    }

    .small-contain {
        padding: 0 20px;
        width: 100%;
    }

    .medium-contain {
        width: 100%;
    }

    .footer .footer-links ul li {
        margin: 0 0 20px 20px;
    }

    .footer {
        text-align: center;
    }

    .padded-text {
        padding: 20px 30px;
    }
}
