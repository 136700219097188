/* modules.scss */

/* Image and Text Module */

.image-text {
    h2 {
        font-weight: $light;
        margin-bottom: 15px;
    }

    .image {
        margin-bottom: 20px;

        img {
            height: auto;
            width: 100%;
        }
    }

    .text {
        color: $dark-grey;
        font-size: 0.9em;
        padding-left: 20px;

        @media (max-width: $bp-med) {
            padding-left: 0;
        }
    }
}

.half.text-module:last-child {
    padding-right: 20px;

    @media (max-width: $bp-med) {
        margin-top: 20px;
        padding-left: 0;
    }
}

/* Text Module */

.text-module {
    color: $dark-grey;
    font-size: 0.9em;
    padding: 20px 0;

    h2 {
        font-weight: $light;
        margin-bottom: 15px;
    }

    ul {
        padding: 0;
    }

    li {
        list-style: none;
        margin: 0 0 20px 20px;
        position: relative;

        &:before {
            content: '•';
            font-size: 0.9em;
            left: -15px;
            line-height: 20px;
            position: absolute;
            vertical-align: middle;
        }
    }

    .btn {
        margin-top: 15px;
    }

    @media (max-width: $bp-med) {
        padding: 0;

        h2 {
            margin-top: 20px;
        }
    }
}

/* Full Image Section */

.full-image {
    background-repeat: no-repeat;
    background-size: cover;

    .contain {
        max-width: 750px;
    }

    h2 {
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 20px;
    }
}

/* List Module */

.half.list-module {
    padding-right: 100px;

    @media (max-width: $bp-med) {
        padding-right: 0;
    }
}

.list-module {
    margin-top: 40px;

    h2 {
        font-size: 1.375em;
        margin-bottom: 20px;
    }

    p {
        color: $dark-grey;
        margin-bottom: 10px;
    }
}

.icon-list {
    margin-left: 20px;

    li:before {
        content: '';
    }

    li {
        list-style: none;
        margin: 35px 0 35px 10px;

        a {
            font-weight: $regular;
            margin-left: 0;
        }
    }
}

/* Tile Modules */

.tile-module {
    border: 1px solid $light-grey;
    border-radius: 5px;
    margin-top: 30px;
    width: 49%;

    @media (max-width: $bp-med) {
        width: 100%;

        &:last-child {
            width: 100%;
        }
    }

    @media (max-width: $bp-large) {
        &.half-with-margin {
            margin: 10px 0;
            width: 100%;
        }
    }

    &:nth-child(odd) {
        margin-left: 0;
    }

    &:nth-child(even) {
        margin-right: 0;
    }

    .text {
        color: $white;
        height: 170px;
        padding: 25px;

        @media (max-width: $bp-med) {
            height: auto;
        }

        h3 {
            font-weight: $light;
            margin-top: 0;
        }

        p {
            font-weight: $light;
        }
    }

    .cta {
        background: $white;
        padding: 10px 15px;
    }
}
