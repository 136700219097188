/* header.scss */

#header {
    margin: auto;
    max-width: 100%;
    padding: 20px;
    position: relative;
    z-index: 100;

    a {
        text-decoration: none;
    }

    .mobile-menu-btn {
        display: none;
    }
    .fieldset-hidden{
        border: 0px solid #c0c0c0;

        .visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap; /* added line */
        border: 0;
    }
    }

    .menu-wrapper {
        float: right;

        @media (min-width: $bp-large-2) {
            //scss-lint:disable ImportantRule
            display: block !important; /* Used when iPad rotates from portrait to landscape */
        }

        .more-nav {
            color: $grey-alt;
            position: relative;

            &:after {
                background: url('../images/hamburger.png') no-repeat;
                content: '';
                height: 14px;
                position: absolute;
                right: 12px;
                top: 16px;
                width: 18px;
            }
        }
    }

    .search-bar #searchsubmit,
    .search-bar .screen-reader-text {
        display: none;
    }

    .search-bar-mobile {
        #searchsubmit,
        .screen-reader-text {
            display: none;
        }
    }

    .contain {
        padding: 0;
    }
}

.logo {
    max-height: 76px;
    max-width: 328px;
    opacity: 1;
}

.hide-login {
    display: none !important; /* !important required to override mobile specific display:block */;
}

.menu-main-menu-container {
    @media (max-width: $bp-large) {
        display: block;
    }

    display: block;
    float: left;
    font-size: 0.9em;
    margin-right: 0;
    opacity: 1;

    li {
        display: inline-block;
        font-weight: $regular;
        padding: 15px;
        text-transform: uppercase;
    }

    .dropdown {
        padding-right: 30px;
    }

    .more-nav {
        padding-right: 40px;
        position: relative;
    }
}

.dropdown {
    border: 1px solid $white;
    position: relative;

    .sub-menu {
        background: $white;
        border: 1px solid $light-grey;
        border-radius: 0 5px 5px;
        box-shadow: 0 2px 9px $black-10;
        display: none;
        left: -1px;
        padding: 0 5px;
        position: absolute;
        top: 45px;
        width: 239px;
        z-index: 0;

        li {
            border-radius: 5px;
            display: block;
            margin: 15px 0;
            padding: 5px 10px;
            text-transform: uppercase;
            transition: 0s;
        }
    }

    &:hover {
        background: $white;
        border-bottom: 1px solid $white;
        border-left: 1px solid $light-grey;
        border-radius: 5px 5px 0 0;
        border-right: 1px solid $light-grey;
        border-top: 1px solid $light-grey;
        box-shadow: 0 -5px 9px $black-10;
        position: relative;

        @media (max-width: $bp-med) {
            background: $white;
            border-bottom: 0;
            border-left: 0;
            border-radius: 0;
            border-right: 0;
            border-top: 0;
            box-shadow: 0;
            position: relative;
        }

        > .sub-menu {
            display: block;
        }

        :before {
            border-bottom: 3px solid $white;
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 100;
        }
    }

    &:after {
        background: url('../images/icon-arrow.png');
        content: '';
        height: 7px;
        position: absolute;
        right: 10px;
        top: 20px;
        width: 11px;
    }
}

.dropdown-login {
    padding: 15px 0;

    .sub-menu {
        background: $white;
        border: 1px solid $light-grey;
        border-radius: 5px 0 5px 5px;
        box-shadow: 0 2px 9px $black-10;
        display: none;
        padding: 10px;
        position: absolute;
        right: 35px;
        top: 51px;
        width: 269px;
        z-index: -1;

        li {
            border-radius: 5px;
            display: block;
            margin: 15px 0;
            padding: 5px 10px;
            text-transform: uppercase;
            transition: 0s;
        }
    }

    form {
        label {
            color: $dark-grey;
            display: block;
            margin: 10px 0 5px;
            text-transform: initial;
        }

        .input {
            border: 1px solid $light-grey;
            border-radius: 5px;
            padding: 7px 10px;
        }

        .btn {
            margin-top: 10px;
        }

        .forgot {
            display: block;
            font-size: 0.8em;
            margin: 10px 0;
            text-decoration: underline;
            text-transform: initial;
        }
    }
}

#loginform,
#loginform_user_group {
    label {
        color: $dark-grey;
        display: block;
        margin: 10px 0 5px;
        text-transform: initial;
    }

    .input {
        border: 1px solid $light-grey;
        border-radius: 5px;
        padding: 7px 10px;
        width: 100%;
    }

    .button-primary {
        background: $white;
        border-radius: 5px;
        color: $dark-grey;
        display: inline-block;
        font-size: 1em;
        font-weight: $regular;
        overflow: hidden;
        padding: 15px 40px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.25s;
        width: 100%;

        &:hover {
            color: $dark-grey;
            transition: 0.1s;
        }
    }
}

.login-container {
    float: right;
    font-size: 0.9em;
    margin-right: 20px;
    margin-top: 13px;

    .open {
        background: $white;
        border-bottom: 0;
        border-left: 1px solid $light-grey;
        border-radius: 5px 5px 0 0;
        border-right: 1px solid $light-grey;
        border-top: 1px solid $light-grey;
        box-shadow: 0 -5px 9px $black-10;
        position: relative;
    }

    .dropdown-login {
        display: inline-block;
        font-weight: 400;
        padding: 15px;
        text-transform: uppercase;
    }

    .login-nav {
        border-radius: 5px;
        padding: 10px 20px;
    }

    @media (max-width: $bp-large) {
        margin-right: 0;
        width: 100%;

        .dropdown {
            border-bottom: 1px solid $light-grey;
            position: relative;
        }

        .dropdown-login {
            border: 0;
            margin-top: 10px;
            padding: 0 10px;
            text-align: center;
            width: 100%;

            form {
                padding: 10px;
            }
        }

        .login-nav {
            display: block;
            width: 100%;
        }

        .open {
            background: $white;
            border-bottom: 0;
            border-left: 0;
            border-radius: 0;
            border-right: 0;
            border-top: 0;
            box-shadow: none;
            position: relative;
        }
    }
}

@media (max-width: $bp-large) {

    #header {
        background: $white;
        height: 92px;
        padding: 0;
        position: absolute;
        width: 100%;
        z-index: 300;

        .contain {
            width: 100%;
        }

        .mobile-menu-btn {
            border-bottom: 1px solid $white;
            border-radius: 0;
            box-shadow: 3px -6px 9px $black-50;
            cursor: pointer;
            display: block;
            height: 92px;
            padding: 20px 42px;
            position: absolute;
            right: 0;
            top: 0;
            width: 144px;

            .text {
                font-size: 1em;
                position: absolute;
                right: 58px;
                text-transform: uppercase;
                top: 37px;
            }
        }
    }

    .menu-main-menu-container {
        float: right;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;
        width: 100%;

        .open {
            border: 0;
            box-shadow: none;
        }

        .more-nav {
            padding-right: 0;

            &:after {
                background: none;
            }
        }

        .dropdown {
            padding-right: 0;
        }
    }

    .dropdown-login .sub-menu,
    .dropdown .sub-menu {
        background: $lighter-grey;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        left: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 0;

        li {
            background: $lighter-grey;
            border: 0;
            color: $med-grey;
            font-size: 0.8em;
            margin: 5px 0;
            padding: 5px 50px;

            &:hover {
                background: none;
                color: $white;
            }

            a {
                padding: 0;
            }
        }
    }

    .menu-wrapper {
        background: $white;
        border-top: 1px solid $light-grey;
        box-shadow: 1px 1px 1px $black-50;
        color: $med-grey;
        display: none;
        float: right;
        font-size: 1em;
        left: 0;
        margin: 0;
        padding: 0 0 20px;
        position: absolute;
        text-align: center;
        top: 91px;
        width: 100%;
        z-index: 0;

        #menu-main-menu {
            margin: 0;
            padding: 0;
        }

        li {
            border-bottom: 1px solid $light-grey;
            clear: both;
            display: block;
            float: left;
            font-size: 1.2em;
            font-weight: $light;
            padding: 0;
            text-align: left;
            width: 100%;

            a {
                color: $med-grey;
                display: block;
                padding: 10px 30px;
            }
        }
    }

    .logo {
        //scss-lint:disable ImportantRule
        height: auto !important; /* Used to overide inline height outputted by wordpress */
        margin: 30px 20px;
        width: 150px !important; /* Used to overide inline width outputted by wordpress */
    }

    .search-bar-mobile {
        display: none;
        padding: 10px;
        position: relative;
        width: 100%;

        &:before {
            background: url('../images/search-icon.png') no-repeat;
            background-size: cover;
            content: '';
            cursor: pointer;
            height: 18px;
            left: 25px;
            position: absolute;
            top: 25px;
            width: 18px;
        }

        input {
            -webkit-appearance: none;
            border: 1px solid $light-grey;
            border-radius: 5px;
            font-size: 1.5em;
            padding: 10px 10px 10px 40px;
            width: 100%;
        }
    }

    #hamburger {
        cursor: pointer;
        height: 25px;
        position: absolute;
        right: 30px;
        top: 41px;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        width: 15px;

        span {
            background: $med-grey;
            border-radius: 9px;
            display: block;
            height: 2px;
            left: 0;
            opacity: 1;
            position: absolute;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
            width: 100%;

        }

        span:nth-child(1) {
            top: 0;
        }

        span:nth-child(2),
        span:nth-child(3) {
            top: 4px;
        }

        span:nth-child(4) {
            top: 8px;
        }

        &.open {
            span:nth-child(1) {
                left: 50%;
                top: 0;
                width: 0%;
            }

            span:nth-child(2) {
                transform: rotate(45deg);
            }

            span:nth-child(3) {
                transform: rotate(-45deg);
            }

            span:nth-child(4) {
                left: 50%;
                top: 0;
                width: 0%;
            }
        }
    }
}

.more-menu {
    cursor: pointer;
    float: right;
    height: 25px;
    position: absolute;
    right: 12px;
    top: 18px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    width: 18px;

    span {
        background: $grey-alt;
        border-radius: 9px;
        display: block;
        height: 2px;
        left: 0;
        opacity: 1;
        position: absolute;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        width: 100%;
    }

    span:nth-child(1) {
        top: 0;
    }

    span:nth-child(2),
    span:nth-child(3) {
        top: 4px;
    }

    span:nth-child(4) {
        top: 8px;
    }

    &.open {
        span:nth-child(1) {
            left: 50%;
            top: 0;
            width: 0%;
        }

        span:nth-child(2) {
            transform: rotate(45deg);
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
        }

        span:nth-child(4) {
            left: 50%;
            top: 0;
            width: 0%;
        }
    }
}

.member-menu-title {
    font-size: 1.5em;
    margin: 10px 0 0;
}

.logged-in-menu {
    background: $cream-grey;
    width: 100%;

    .logged-in-menu-ul {
        margin: 0;
        padding: 0;
        text-align: center;

        li {
            color: $med-grey;
            display: inline-block;
            font-size: 0.75em;
            list-style: none;
            padding: 15px;
            text-transform: uppercase;

            &:hover {
                color: $dark-grey;
            }
        }
    }
}

.menu-logged-in-menu-container {
    display: inline-block;

    @media (max-width: $bp-large) {
        width: 100%;

        #menu-logged-in-menu {
            margin: 0;
        }
    }
}

.scroll-top {
    background: $white url('../images/icon-up-arrow.png') no-repeat center;
    border-radius: 100px;
    bottom: 20px;
    box-shadow: 0 0 5px $black-50;
    height: 47px;
    position: fixed;
    right: 20px;
    width: 47px;
    z-index: 100;
}
