/* generic-icons.scss */

.icon {
    margin-left: 32px;
    position: relative;

    &:before {
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 20px;
        left: -28px;
        position: absolute;
        top: 0;
        width: 16px;

    }
}

.icon-social-fb:before,
.icon-social-twitter:before,
.icon-social-linkedin:before {
    font-size: 2em;
}
