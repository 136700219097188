/* hero.scss */

.big-hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    height: 410px;
    position: relative;
    width: 100%;

    @media (max-width: $bp-large) {
        margin-top: 92px;
        min-height: 400px;
    }

    .contain,
    .medium-contain {
        height: 100%;
    }

    h1 {
        color: $white;
        font-size: 2.375em;
        font-weight: $extra-light;
    }

    p {
        font-size: 1.2em;
        font-weight: $light;
        letter-spacing: 1px;
    }

    .vert-table {
        width: 100%;

        @media (max-width: $bp-med)  {
            padding: 20px 0;
        }

        .text-middle {
            padding: 0;
        }
    }

    .btn {
        margin-top: 20px;
    }
}

.slim-hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    height: 240px;
    width: 100%;

    h1 {
        color: $white;
        font-size: 3em;
    }

    .contain,
    .medium-contain {
        height: 100%;
    }

    @media (max-width: $bp-large) {
        margin-top: 92px;
        min-height: 248px;
    }

    .text-middle {
        padding: 0;

        @media (max-width: $bp-large) {
            bottom: 0;
            display: table-cell;
            position: relative;
            text-align: center;
        }
    }

    .vert-table {
        width: 100%;
    }
}
