/* generic-misc.scss */

.faded-text {
    height: 100px;
    overflow: hidden;
    position: relative;
}

.padded-text {
    font-size: 1.5em;
    padding: 75px;
}

.no-pad {
    padding: 0;
}

.no-marg {
    margin: 0;
}

.marg-bot {
    margin-bottom: 50px;
}

.clear {
    clear: both;
}

.image {
    background-position: center;
    background-size: cover;
}

.hidden {
    opacity: 0;
}

.show {
    opacity: 1;
}

@media (max-width: $bp-large-2) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}

@media (min-width: $bp-large-2) {
    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }
}
