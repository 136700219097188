/* generic-colors.scss */

.grey-text {
    color: $med-grey;
}

.dark-grey-text {
    color: $dark-grey;
}

.white-text {
    color: $white;
}

.light-gray-text {
    color: $light-grey;
}

.blue-text {
    color: $blue;
}
