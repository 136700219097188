/* generic-containers.scss */

.contain {
    clear: both;
    margin: auto;
    max-width: 1200px;
    padding: 0 50px;
    position: relative;
}

.small-contain {
    clear: both;
    margin: auto;
    max-width: 500px;
    position: relative;
    width: 50%;
}

.medium-contain {
    clear: both;
    margin: auto;
    max-width: 970px;
    padding: 0 20px;
    position: relative;
}

.large-contain {
    clear: both;
    margin: auto;
    position: relative;
    width: 100%;
}

.section {
    background-position: center;
    background-size: cover;
    clear: both;
    float: left;
    padding: 20px 0;
    width: 100%;

    h2 {
        margin-bottom: 20px;

        @media (max-width: $bp-large) {
            margin-bottom: 0;
        }
    }
}

.section-sm-padding {
    background-position: center;
    background-size: cover;
    clear: both;
    float: left;
    padding: 30px 0;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }
}

.content {
    clear: both;
    overflow: hidden;
    width: 100%;
}

.three-column-section .contain .third-with-margin:last-child {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 700px) {
        justify-content: flex-start;
    }
}
