/* variables.scss */

/* Colors */

$light-grey: #dedbdb;
$med-grey: #545454;
$dark-grey: #373737;
$lighter-grey: #eee;
$grey-alt: #5b5858;
$cream-grey: #ececec;
$white: #fff;
$off-white: #d6d5d5;
$blue: #163872;
$green: #395c1f;
$red: #aa1810;

/* Rgb Colors */
$black-10: rgba(0, 0, 0, 0.1);
$black-20: rgba(0, 0, 0, 0.2);
$black-50: rgba(0, 0, 0, 0.5);
$white-10: rgba(255, 255, 255, 0.1);

$primarycolor: #34abc3;
$secondarycolor: #aa1810;

/* Fonts */

$primarytext: 'Work Sans', sans-serif;

/* Breakpoints */

$bp-small : 25.875em;
$bp-med : 46.8em;
$bp-large : 64em;
$bp-xl : 75em;
$bp-large-2 : 1025px;

/* Font Weights */

$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
