/* generic-styles.scss */

html,
body {
    background: $white;
    color: $dark-grey;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: $primarytext;
    font-size: 100%;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
