/* contact.scss */

.contact-item {
    margin-bottom: 0;

    h5 {
        color: $med-grey;
        font-weight: $medium;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    span {
        font-weight: $light;
    }
}

.contact-section {
    .info {
        padding: 75px;

        @media (max-width: $bp-large) {
            padding: 20px;
        }
    }

    .map-form {
        background: $cream-grey;
        padding: 75px;

        @media (max-width: $bp-large) {
            padding: 20px;
        }

        .form {
            margin-top: 20px;
        }

        .btn {
            margin-top: 20px;
        }
    }
}

.get-directions {
    margin: 10px 0;
}
