/* footer.scss */

.footer {
    border-top: 2px solid $lighter-grey;
    clear: both;
    display: inline-block;
    overflow: hidden;
    padding: 25px 20px;
    width: 100%;

    a {
        text-decoration: none;
    }

    ul li {
        display: inline-block;
        font-weight: $semi-bold;
        margin: 20px 5px;

        @media (max-width: $bp-med) {
            margin: 10px;
        }
    }

    .social {
        ul {
            margin: 20px 0;

            li {
                margin: 0;

                &:first-child {
                    margin-left: 15px;
                }
            }
        }

        @media (max-width: $bp-large) {
            margin-top: 0;
            text-align: center;
        }
    }

    .logo {
        padding-right: 20px;
    }

    .footer-links {
        ul li {
            display: block;
            font-size: 0.9em;
            font-weight: normal;
            margin: 15px 0;
            text-align: left;
            text-transform: uppercase;
        }

        @media (max-width: $bp-small) {
            width: 50%;

            &:last-child {
                width: 100%;
            }
        }
    }
}

.sub-footer {
    background: $cream-grey;
    color: $dark-grey;
    font-size: 0.85em;
    padding: 20px;
    text-align: center;
}
