/* error.scss */

.error-result {
    margin-bottom: 100px;
    padding: 40px 0;

    .btn {
        margin-top: 30px;
    }
}
