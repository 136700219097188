/* generic-headers.scss */

h1,
h2,
h3,
h4 {
    font-weight: lighter;
    line-height: 1.25em;
    margin: 5px 0;
}

h1 {
    font-size: 2.25em;
}

h2 {
    font-size: 2.125em;
    font-weight: $light;
}

h3 {
    font-size: 1.25em;
}

h4 {
    font-size: 1.1em;
}

.section-title {
    margin-bottom: 20px;

    span {
        display: block;
        margin-top: 20px;
    }

    @media (max-width: $bp-med) {
        margin-bottom: 20px;
    }
}

.center-intro {
    clear: both;
    margin: 0 auto 20px;
    width: 60%;
}
