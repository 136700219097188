/* generic-widths.scss */

.width-10 {
    float: left;
    width: 10%;
}

.width-20 {
    float: left;
    width: 20%;
}

.width-30 {
    float: left;
    width: 30%;
}

.width-40 {
    float: left;
    width: 40%;
}

.half {
    float: left;
    width: 50%;
}

.half-with-margin {
    float: left;
    margin: 0 1%;
    width: 48%;

    &:first-child {
        margin-left: 0;
        width: 49%;
    }

    &:last-of-type {
        margin-right: 0;
        width: 49%;
    }
}

.width-60 {
    float: left;
    width: 60%;
}

.width-70 {
    float: left;
    width: 70%;
}

.width-80 {
    float: left;
    width: 80%;
}

.width-90 {
    float: left;
    width: 90%;
}

.width-100 {
    float: left;
    width: 100%;
}

.row {
    clear: both;
    float: left;
    width: 100%;
}

.third {
    float: left;
    width: 33.3333%;
}

.third-with-margin {
    float: left;
    margin: 0 1%;
    width: 31%;
}

.two-third {
    float: left;
    width: 66.6666%;
}

.quarter {
    float: left;
    width: 24.9%;
}

.quarter-with-margin {
    float: left;
    margin: 0 1%;
    width: 23%;
}

.three-quarter {
    float: left;
    width: 75%;
}
