/* generic-text-layouts.scss */

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

span,
p {
    color: inherit;
    line-height: 1.25em;
}

.italic {
    font-style: italic;
}

.flex {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    width: auto;
}

.bold {
    font-weight: 700;
}

.semi-bold {
    font-weight: 600;
}

.regular {
    font-weight: normal;
}

em {
    font-style: italic;
}

p {
    font-weight: $regular;
    line-height: 1.25em;
}

a {
    color: inherit;
}

strong {
    font-weight: 600;
}

ul {
    padding: 0;
}

.uppercase {
    text-transform: uppercase;
}

.normal {
    font-weight: 400;
}

.light {
    font-weight: 400;
}

.line-break {
    display: block;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.list {
    color: $dark-grey;
    font-size: 0.875em;

    ul {
        padding: 0;
    }

    li {
        list-style: none;
        margin: 0 0 20px 20px;
        position: relative;

        &:before {
            color: $red;
            content: '•';
            font-size: 0.9em;
            left: -15px;
            line-height: 20px;
            position: absolute;
            vertical-align: middle;
        }
    }
}

/* Accordion */

.accordion-list {
    padding: 0;

    li {
        border-bottom: 1px solid $light-grey;
        clear: both;
        list-style: none;
        padding: 20px 40px 20px 0;
        position: relative;

        &:hover {
            transition: 0.25s;
        }

        &::before {
            background: $med-grey;
            content: '';
            height: 2px;
            position: absolute;
            right: 0;
            top: 16px;
            transition: 0s;
            width: 14px;
        }

        &::after {
            background: $med-grey;
            content: '';
            height: 14px;
            position: absolute;
            right: 6px;
            top: 10px;
            transition: 0s;
            width: 2px;
        }

        &:last-child {
            border-bottom: 0;
        }

        p {
            color: $med-grey;
        }

        .hide {
            display: none;
        }
    }
}

.font-small {
    font-size: 0.9em;
}

.font-medium {
    font-size: 1.1em;
}

.font-large {
    font-size: 1.3em;
}

.vert-table {
    display: table;
    height: 100%;

    .text-middle {
        display: table-cell;
        padding: 50px 0;
        vertical-align: middle;

        @media (max-width: $bp-med) {
            padding: 0;
        }

        .text {
            max-width: 530px;
        }

        .center-element {
            margin: auto;

            span {
                font-size: 1.5em;
            }
        }
    }

    @media (max-width: $bp-xl) {
        .text {
            margin: auto;
        }
    }

    @media (max-width: $bp-med) {
        padding: 20px;
    }
}

.intro {
    p {
        color: $dark-grey;
        font-size: 1.375em;
        font-weight: $light;
    }
}

.equal-height {
    .item {
        //scss-lint:disable ImportantRule
        @media (max-width: $bp-med) {
            height: auto !important;
        }
    }
}
