/* events.scss */

.featured-event-title {
    font-size: 0.95em;
    font-weight: $regular;
}

.event-summaries {
    h3 {
        font-size: 1.4em;
        font-weight: $regular;
    }

    h4 {
        color: $med-grey;
        font-weight: $regular;
    }
}

.event-section {
    clear: both;
    overflow: hidden;

    h2 {
        font-weight: $light;
        margin: 20px 0 0;
    }

    .text-module:first-child {
        padding-top: 0;
    }

    p {
        color: $dark-grey;
        margin: 10px 0;
    }
}

.event-summary {
    border-bottom: 1px solid $light-grey;

    @media (max-width: $bp-med) {
        margin-top: 20px;
    }

    .vert-table {
        width: 100%;

        .text-middle {
            padding: 0;
        }

        @media (max-width: $bp-med) {
            padding: 0 0 20px;
        }
    }

    h3 {
        font-weight: $light;
    }

    .btn-container {
        padding-left: 20px;

        @media (max-width: $bp-med) {
            padding-left: 0;
        }
    }
}

.event-thumb {
    padding-right: 20px;
}

.thumb {
    display: block;
    float: left;
    height: auto;
    margin: 10px 20px 10px 0;
    width: 201px;
}
