/* search-result.scss */

.results {
    h5 {
        color: $med-grey;
        font-weight: $medium;
    }

    h3 {
        font-weight: $medium;
    }

    .result {
        border-bottom: 2px solid $lighter-grey;
        padding: 30px 0;

        &:last-child {
            border-bottom: 0;
        }
    }
}
