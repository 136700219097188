/* members.scss */

.team-area {
    margin-bottom: 30px;
    overflow: hidden;

    @media (max-width: $bp-large) {
        h2 {
            margin-bottom: 20px;
        }
    }

    h3 {
        margin-bottom: 5px;
    }

    .member {
        border: 1px solid $light-grey;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 20px 20px 90px;
        position: relative;
        width: 49%;

        @media (max-width: $bp-med) {
            width: 100%;

            &:last-child {
                width: 100%;
            }
        }

        .image-profile {
            margin-right: 20px;
            max-height: 105px;
            max-width: 105px;
            overflow: hidden;

        }

        img {
            height: auto;
            width: 100%;
        }

        h5 {
            color: $med-grey;
            font-weight: $medium;
            margin-top: 5px;
            text-transform: uppercase;
        }

        &:nth-child(odd) {
            margin-left: 1%;
            margin-right: 0;
        }

        &:nth-child(even) {
            margin-left: 0;
            margin-right: 1%;
        }
    }
}

.member-button-container {
    bottom: 0;
    left: 0;
    padding: 20px;
    position: absolute;
    width: 100%;
}

.member-desc {
    display: block;
    max-height: 60px;
}
